<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Aporte - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
              

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select disabled placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchClients" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Boleta:">
                    <b-form-input disabled class="text-center" readonly type="text" v-model="contribution.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select disabled  v-model="contribution.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button disabled type="button" variant="primary" class="form-control">Aportes</b-button>
                  </b-form-group>
                </b-col>
               
                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-textarea disabled type="text" v-model="contribution.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Anexos:">
                     <b-form-file
                     disabled
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                  </b-form-group>
                </b-col>

            <div class="col-md-12">
            <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="30%" class="text-center">Año/Mes</th>
                    <th width="20%" class="text-center">Aporte</th>
                    <th width="20%" class="text-center">Sepelio</th>
                    <th width="20%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in contributions_detail" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.year +" / "+ NameMonth(item.month) }}</td>
                      <td class="align-middle text-right">{{item.contribution}}</td>
                      <td class="align-middle text-right">{{item.burial}}</td>
                      <td class="align-middle text-right">{{item.total}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
                

                <b-col md="10">
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total">
                    <b-form-input type="number" class="text-right" disabled step="any" v-model="contribution.total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="3"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="DataPrint" class="form-control" variant="warning" ><i class="fas fa-print"></i> Imprimir Ticket</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="button" :disabled="contribution.file.length == 0" @click="ViewPDF" class="form-control" variant="info" ><i class="fas fa-print"></i> Ver Anexo</b-button>
                </b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/aporte/listar' }" append >REGRESAR</b-link>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
    <ModalContribution />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
import ModalContribution from './components/ModalContribution'
import ContributionDetail from './components/ContributionDetail'
export default {
  name: "UsuarioAdd",
  props: ["id_contribution"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalContribution,
      ContributionDetail,
  },
  data() {
    return {
      isLoading: false,
      module: 'Contribution',
      role: 5,
      contribution: {
          id_contribution:'',
          id_partner:'',
          number:'',
          observation:'',
          file:'',
          payment_method:'008',
          total:'0.00',
          state:1,
      },
      contributions_detail:[],
      file:null,
      partners: [],
      partner:null,
      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      twist_type:[
        {value:'1',text:'Sepelio'},
        {value:'2',text:'Liquidación de Aportes'},
        {value:'3',text:'Gasto Administrativo'},
        {value:'4',text:'Prestamo Ordinario'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        id_partner: false,
        payment_method: false,
        number: false,
        contributions_detail: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.mLoadResetContributionDetail();
    this.ViewContribution();
  },
  methods: {
    ViewContribution,
    ModalContributionShow,
    EditContribution,
    Validate,
    SearchClients,
    modalClients,
    onFileChange,
    NameMonth,
    ViewPDF,

    DataPrint,
    Print,
    ...mapActions('Contribution',['mLoadResetContributionDetail']),
    ...mapActions('Contribution',['mLoadAddContributionDetail']),
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function NameMonth(code) {
  let name = '';
  switch (code) {
    case '01': name = 'Enero'; break;
    case '02': name = 'Febrero'; break;
    case '03': name = 'Marzo'; break;
    case '04': name = 'Abril'; break;
    case '05': name = 'Mayo'; break;
    case '06': name = 'Junio'; break;
    case '07': name = 'Julio'; break;
    case '08': name = 'Agosto'; break;
    case '09': name = 'Septiembre'; break;
    case '10': name = 'Octubre'; break;
    case '11': name = 'Noviembre'; break;
    case '12': name = 'Diciembre'; break;
  
  }
  return name;
}

function ViewContribution() {
  let id_contribution = je.decrypt(this.id_contribution);
  let me = this;
  let url = me.url_base + "contribution/view/"+id_contribution;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.contribution.id_contribution = response.data.result.contribution.id_contribution;
          me.contribution.id_partner = response.data.result.contribution.id_partner;
          me.contribution.number = response.data.result.contribution.number;
          me.contribution.observation = response.data.result.contribution.observation;
          me.contribution.file = response.data.result.contribution.file;
          me.contribution.payment_method = response.data.result.contribution.payment_method;
          me.contribution.total = response.data.result.contribution.total;
          me.contribution.state = response.data.result.contribution.state;
          me.partner = { id: response.data.result.contribution.id_partner, full_name: response.data.result.contribution.partner_name + " - " + response.data.result.contribution.partner_document_number}
          me.contributions_detail = response.data.result.contributions_detail;

       
      }else{
     
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}
function ViewPDF() {
  let me = this;
  let url = me.url_base + this.contribution.file;
  window.open(url,'_blank');
}


function onFileChange(e) {
  this.check.file = e.target.files[0];
}

function ModalContributionShow() {
  if (this.partner != null) {
    EventBus.$emit('ModalContributionShow',this.partner.id, this.role);
  }
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function EditContribution() {
  let me = this;
  let url = me.url_base + "contribution/edit";
  me.contribution.id_partner = me.partner.id;
  me.contribution.id_user = me.user.id_user;
  me.contribution.contributions_detail = me.contributions_detail;
  me.contribution.total = me.total;
  let data = this.contribution;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.payment_method = this.contribution.payment_method.length == 0 ? true : false;
  this.errors.number = this.contribution.number.length == 0 ? true : false;
  this.errors.contributions_detail = this.contributions_detail.length == 0 ? true : false;
  this.errors.total = this.total.length == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.contributions_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el aporte ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditContribution();
    }
  });
}

function DataPrint() {
  let me = this;
  let id_contribution = je.decrypt(this.id_contribution);
  let url = me.url_base + "contribution/data-print/"+id_contribution;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result)
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}
</script>
